import { navigate } from "gatsby";
import React, { useReducer } from "react";
import Card from "../components/controls/card";
import Input from "../components/controls/input";
import Select from "../components/controls/select";
import Head from "../components/layout/head";
import Root from "../components/layout/root";
import PageProps from "../core/page-props";
import {
  createQueryString,
  parseQueryString,
  QueryString
} from "../core/query-string";
import { Inventory } from "../data/inventory";
import Vehicle from "../data/vehicle";
import prettifyNumber from "../core/prettify-number";

type Sorting = {
  field: "year" | "price";
  order: "ASC" | "DESC";
};

type State = {
  vehicles: any[];
  visibleVehicles: any[];
  year: [string, string];
  price: [string, string];
  brand: string;
  model: string;
  section: string;
  sorting: Sorting;
};

type Filter = {
  year: [string, string];
  price: [string, string];
  brand: string;
  model: string;
  section: string;
};

type Action =
  | { type: "SET_STATE"; state: State }
  | { type: "SET_YEAR"; min: string; max: string }
  | { type: "SET_PRICE"; min: string; max: string }
  | { type: "SET_BRAND"; brand: string }
  | { type: "SET_MODEL"; model: string }
  | { type: "SET_SORTING"; sorting: Sorting }
  | { type: "SET_SECTION"; section: string };

const Stock: React.FC<PageProps> = ({ location }) => {
  function updateUrl(queryString: QueryString) {
    const url = `${location.origin}${location.pathname}${createQueryString(
      queryString
    )}`;
    window.history.replaceState(queryString, document.title, url);
  }

  function filterVehicles(vehicles: Vehicle[], filters: Filter) {
    return vehicles.filter(vehicle => {
      if (filters.brand !== "" && vehicle.brand !== filters.brand) {
        return false;
      }

      if (filters.model !== "" && vehicle.model !== filters.model) {
        return false;
      }

      if (filters.section !== "" && vehicle.section !== filters.section) {
        return false;
      }

      if (
        filters.year[0] !== "" &&
        parseInt(vehicle.year) < parseInt(filters.year[0])
      ) {
        return false;
      }

      if (
        filters.year[1] !== "" &&
        parseInt(vehicle.year) > parseInt(filters.year[1])
      ) {
        return false;
      }
      if (
        filters.price[0] !== "" &&
        parseFloat(vehicle.price) < parseFloat(filters.price[0])
      ) {
        return false;
      }

      if (
        filters.price[1] !== "" &&
        parseFloat(vehicle.price) > parseFloat(filters.price[1])
      ) {
        return false;
      }

      return true;
    });
  }

  function sortVehicles(vehicles: Vehicle[], sorting: Sorting) {
    return vehicles.sort((a, b) => {
      switch (sorting.field) {
        case "price":
          switch (sorting.order) {
            case "ASC":
              return parseFloat(a.price) - parseFloat(b.price);
            case "DESC":
              return parseFloat(b.price) - parseFloat(a.price);
          }
        case "year":
          switch (sorting.order) {
            case "ASC":
              return parseInt(a.year) - parseInt(b.year);
            case "DESC":
              return parseInt(b.year) - parseInt(a.year);
          }
      }
    });
  }

  function reducer(state: State, action: Action): State {
    updateUrl({
      section: state.section,
      price:
        action.type === "SET_PRICE" ? [action.min, action.max] : state.price,
      year: action.type === "SET_YEAR" ? [action.min, action.max] : state.year,
      brand: action.type === "SET_BRAND" ? action.brand : state.brand,
      model: action.type === "SET_MODEL" ? action.model : state.model,
      order:
        action.type === "SET_SORTING"
          ? [action.sorting.field, action.sorting.order]
          : [state.sorting.field, state.sorting.order]
    });

    switch (action.type) {
      case "SET_STATE":
        return {
          ...state,
          ...action.state,
          visibleVehicles: filterVehicles(state.vehicles, {
            ...state,
            ...action.state
          })
        };
      case "SET_SECTION":
        return {
          ...state,
          section: action.section,
          visibleVehicles: filterVehicles(state.vehicles, {
            ...state,
            section: action.section
          })
        };
      case "SET_BRAND":
        return {
          ...state,
          brand: action.brand,
          model: action.brand === "" ? "" : state.model,
          visibleVehicles: filterVehicles(state.vehicles, {
            ...state,
            brand: action.brand
          })
        };
      case "SET_MODEL":
        return {
          ...state,
          model: action.model,
          visibleVehicles: filterVehicles(state.vehicles, {
            ...state,
            model: action.model
          })
        };
      case "SET_PRICE":
        return {
          ...state,
          price: [action.min, action.max],
          visibleVehicles: filterVehicles(state.vehicles, {
            ...state,
            price: [action.min, action.max]
          })
        };
      case "SET_YEAR":
        return {
          ...state,
          year: [action.min, action.max],
          visibleVehicles: filterVehicles(state.vehicles, {
            ...state,
            year: [action.min, action.max]
          })
        };
      case "SET_SORTING":
        return {
          ...state,
          sorting: action.sorting,
          visibleVehicles: sortVehicles(state.vehicles, action.sorting)
        };
      default:
        return state;
    }
  }

  function getBrands(vehicles: Vehicle[]) {
    return [...new Set(vehicles.map(x => x.brand))].sort();
  }

  function getModels(vehicles: Vehicle[]) {
    return [...new Set(vehicles.map(x => x.model))].sort();
  }

  const vehicles: Vehicle[] = Inventory();

  let {
    year = ["", ""],
    price = ["", ""],
    brand = "",
    model = "",
    section = ""
  } = parseQueryString(location.search) as Filter;

  if (!Array.isArray(year)) {
    year = [year, ""];
  }

  if (!Array.isArray(price)) {
    price = [price, ""];
  }

  const initialState: State = {
    vehicles: vehicles,
    visibleVehicles: filterVehicles(vehicles, {
      year,
      price,
      brand,
      model,
      section
    }),
    sorting: {
      field: "year",
      order: "DESC"
    },
    year,
    price,
    brand,
    model,
    section
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  if (section !== state.section) {
    dispatch({
      type: "SET_SECTION",
      section: section
    });
  }

  function generateSteps(step: number, limit: number, start: number = 0) {
    const steps = [];
    for (let i = start; i < limit + step; i += step) {
      steps.push(i);
    }
    return steps;
  }

  const maxPrice = Math.max(...vehicles.map(x => parseInt(x.price)));
  const maxYear = Math.max(...vehicles.map(x => parseInt(x.year)));
  const minYear = Math.min(...vehicles.map(x => parseInt(x.year)));

  const priceOptions = generateSteps(5000, maxPrice).map(num => {
    return {
      label: prettifyNumber(num, 0),
      value: `${num}`
    };
  });

  const yearOptions = generateSteps(1, maxYear, minYear).map(num => {
    return {
      label: `${num}`,
      value: `${num}`
    };
  });

  return (
    <Root>
      <Head title="Stock" />
      <div className="container pt-6 pb-8 w-full mb-4 flex flex-wrap justify-between px-1">
        <Select
          className="w-1/2 px-2 py-2"
          id="filter-brand"
          label="Fabricante"
          selected={state.brand}
          options={[
            { value: "", label: "" },
            ...getBrands(
              state.vehicles.filter(x => {
                return section.length > 0 ? x.section === section : true;
              })
            ).map(x => {
              return {
                label: x,
                value: x
              };
            })
          ]}
          disabled={false}
          onChange={e => {
            dispatch({
              type: "SET_MODEL",
              model: ""
            });
            dispatch({
              type: "SET_BRAND",
              brand: e.target.value
            });
          }}
        />
        <Select
          className="w-1/2 px-2 py-2"
          id="filter-model"
          label="Modelo"
          selected={state.model}
          options={[
            { value: "", label: "" },
            ...getModels(
              state.vehicles.filter(x => {
                return section.length > 0
                  ? x.section === section
                  : true && x.brand === state.brand;
              })
            ).map(x => {
              return {
                label: x,
                value: x
              };
            })
          ]}
          disabled={!state.brand}
          onChange={e => {
            dispatch({
              type: "SET_MODEL",
              model: e.target.value
            });
          }}
        />

        <Select
          className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 px-2 py-2"
          id="price-min"
          label="Preço de"
          options={[
            {
              label: "",
              value: ""
            },
            ...priceOptions
          ]}
          selected={state.price[0]}
          onChange={e => {
            dispatch({
              type: "SET_PRICE",
              min: e.target.value,
              max: state.price[1] || ""
            });
          }}
        />
        <Select
          className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 px-2 py-2"
          id="price-max"
          label="Preço até"
          options={[
            {
              label: "",
              value: ""
            },
            ...priceOptions
          ]}
          selected={state.price[1]}
          onChange={e => {
            dispatch({
              type: "SET_PRICE",
              min: state.price[0] || "",
              max: e.target.value
            });
          }}
        />
        <Select
          className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 px-2 py-2"
          id="year-min"
          label="Ano de"
          options={[
            {
              label: "",
              value: ""
            },
            ...yearOptions
          ]}
          selected={state.year[0]}
          onChange={e => {
            dispatch({
              type: "SET_YEAR",
              min: e.target.value,
              max: state.year[1] || ""
            });
          }}
        />
        <Select
          className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 px-2 py-2"
          id="year-max"
          label="Ano até"
          options={[
            {
              label: "",
              value: ""
            },
            ...yearOptions
          ]}
          selected={state.year[1]}
          onChange={e => {
            dispatch({
              type: "SET_YEAR",
              min: state.year[0] || "",
              max: e.target.value
            });
          }}
        />
      </div>
      <div className="container pt-6 w-full flex flex-col md:flex-row px-1 md:items-center">
        <p className="flex-1 px-2 py-2 font-semibold text-tertiary-700">
          Encontrados{" "}
          <span className="font-bold text-tertiary-500">
            {state.visibleVehicles.length}
          </span>{" "}
          resultados
        </p>
        <Select
          className="px-2 py-2"
          id="sort"
          options={[
            { label: "Preço, mais baixo", value: "price.ASC" },
            { label: "Preço, mais alto", value: "price.DESC" },
            { label: "Ano, mais baixo", value: "year.ASC" },
            { label: "Ano, mais alto", value: "year.DESC" }
          ]}
          selected={`${state.sorting.field}.${state.sorting.order}`}
          disabled={false}
          onChange={e => {
            // TODO: Find a more elegant way to do this.
            const [field, order] = e.target.value.split(".") as [
              "price" | "year",
              "ASC" | "DESC"
            ];
            dispatch({
              type: "SET_SORTING",
              sorting: {
                field: field,
                order: order
              }
            });
          }}
        />
      </div>
      {state.visibleVehicles.length > 0 ? (
        <div className="container flex flex-wrap mb-10">
          {[
            ...state.visibleVehicles.map((vehicle: Vehicle, index: number) => {
              return (
                <Card
                  className={
                    "flex-auto w-full sm:w-1/2 md:w-1/3 xl:w-1/4 mx-3 my-3"
                  }
                  key={vehicle.id}
                  make={vehicle.brand}
                  model={vehicle.model}
                  year={parseInt(vehicle.year)}
                  price={parseInt(vehicle.price)}
                  mileage={parseInt(vehicle.kms)}
                  transmission={
                    vehicle.transmission === "manual" ? "manual" : "auto"
                  }
                  image={vehicle.images[0]}
                  onClick={() => {
                    navigate(`/vehicles/${vehicle.id}`);
                  }}
                />
              );
            }),
            // Ghost divs to prevent items from filling in empty space in the last row.
            ...[...Array(3).keys()].map((_, i) => {
              return (
                <div
                  key={i}
                  className={
                    "invisible flex-auto w-full sm:w-1/2 md:w-1/3 xl:w-1/4 mx-3 my-3"
                  }
                />
              );
            })
          ]}
        </div>
      ) : (
        <div className="container text-center">
          <p className="my-32 text-2xl font-semibold">
            Não existem anúncios para a sua pesquisa
          </p>
        </div>
      )}
    </Root>
  );
};

export default Stock;
